@media screen and (max-width: 768px) {
  .masthead-section {
    background-image: url("../images/landing-page/landing-page-mobile.webp");
    padding: 4rem 0;

    .masthead-pattern-container {
      background-image: url("../images/landing-page/m_pattern.svg");
      // background-image: none;
    }

    img {
      top: 0;
    }

    .masthead-info-container {
      padding: 1.5rem;
      border-radius: 1rem;
    }
  }

  // .two-sections-container {
  //     background-image: url("../images/landing-page/s-pattern.svg");
  //     background-size: cover;
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     background-color: $color-chaste-blossoms-100;
  // }

  .who-are-we-section {
    // background-image: url("../images/landing-page/m_pattern.svg");
    background-image: none;
    // background-color: transparent;

    .belief-quote-container {
      border-radius: 0 0 1rem 1rem;
      background: $color-chaste-blossoms-500;
      padding: 2rem 3rem;
    }

    .who-are-we-info-container {
      padding: 1.5rem;
      background-color: $color-chaste-blossoms-50;
      border-radius: 1rem 1rem 0 0;

      .who-are-we-card {
        border-radius: 1rem;
      }
    }
  }

  /* Our Services Section */

  .our-services-section {
    // background-image: url("../images/landing-page/m_pattern.svg");
    background-image: none;
    // background-color: transparent;

    h1 {
      margin-bottom: 3.5rem;
    }

    .our-service-card {
      background-color: $color-white;
      padding: 1.5rem;
      border-radius: 2rem;
    }
  }

  /* Footer */

  .footer {
    background-color: $color-grey-900;
    padding: 2rem 0 4rem;
    overflow-x: hidden;

    &__logo {
      img {
        max-width: 14rem;
      }
    }

    hr {
      height: 0.125rem;
      color: $color-white;
      margin-bottom: 3.5rem;
    }

    .social-media-container {
    }
  }

  /* Thank You Page */

  .thank-you-container {
    background-image: url("../images/thank-you-page/m_masthead-scribbles.webp");
    padding: 4rem 0 0;

    .thank-you-pattern-container {
      // background-image: url("../images/landing-page/m-pattern.svg");
      // background-image: none;
    }

    img {
      height: 100%;
    }
  }

  /* Privacy Policy & Terms & Conditions Pages */

  #privacy-policy-page,
  #terms-conditions-page {
    background-image: none;
    .terms-container {
      padding: 1.5rem;
    }
  }
}
