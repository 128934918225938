.color-white {
    color: $color-white;
}

.color-black {
    color: $color-black;
}

.color-migol-blue-900 {
    color: $color-migol-blue-900;
}

.color-migol-blue-800 {
    color: $color-migol-blue-800;
}

.color-migol-blue-700 {
    color: $color-migol-blue-700;
}

.color-migol-blue-600 {
    color: $color-migol-blue-600;
}

.color-migol-blue-500 {
    color: $color-migol-blue-500;
}

.color-migol-blue-400 {
    color: $color-migol-blue-400;
}

.color-migol-blue-300 {
    color: $color-migol-blue-300;
}

.color-migol-blue-200 {
    color: $color-migol-blue-200;
}

.color-migol-blue-100 {
    color: $color-migol-blue-100;
}

.color-migol-blue-50 {
    color: $color-migol-blue-50;
}

.color-shimmering-expanse-cyan-900 {
    color: $color-shimmering-expanse-cyan-900;
}

.color-shimmering-expanse-cyan-800 {
    color: $color-shimmering-expanse-cyan-800;
}

.color-shimmering-expanse-cyan-700 {
    color: $color-shimmering-expanse-cyan-700;
}

.color-shimmering-expanse-cyan-600 {
    color: $color-shimmering-expanse-cyan-600;
}

.color-shimmering-expanse-cyan-500 {
    color: $color-shimmering-expanse-cyan-500;
}

.color-shimmering-expanse-cyan-400 {
    color: $color-shimmering-expanse-cyan-400;
}

.color-shimmering-expanse-cyan-300 {
    color: $color-shimmering-expanse-cyan-300;
}

.color-shimmering-expanse-cyan-200 {
    color: $color-shimmering-expanse-cyan-200;
}

.color-shimmering-expanse-cyan-100 {
    color: $color-shimmering-expanse-cyan-100;
}

.color-shimmering-expanse-cyan-50 {
    color: $color-shimmering-expanse-cyan-50;
}

.color-amber-glow-900 {
    color: $color-amber-glow-900;
}

.color-amber-glow-800 {
    color: $color-amber-glow-800;
}

.color-amber-glow-700 {
    color: $color-amber-glow-700;
}

.color-amber-glow-600 {
    color: $color-amber-glow-600;
}

.color-amber-glow-500 {
    color: $color-amber-glow-500;
}

.color-amber-glow-400 {
    color: $color-amber-glow-400;
}

.color-amber-glow-300 {
    color: $color-amber-glow-300;
}

.color-amber-glow-200 {
    color: $color-amber-glow-200;
}

.color-amber-glow-100 {
    color: $color-amber-glow-100;
}

.color-amber-glow-50 {
    color: $color-amber-glow-50;
}

.color-lucid-pink-900 {
    color: $color-lucid-pink-900;
}

.color-lucid-pink-800 {
    color: $color-lucid-pink-800;
}

.color-lucid-pink-700 {
    color: $color-lucid-pink-700;
}

.color-lucid-pink-600 {
    color: $color-lucid-pink-600;
}

.color-lucid-pink-500 {
    color: $color-lucid-pink-500;
}

.color-lucid-pink-400 {
    color: $color-lucid-pink-400;
}

.color-lucid-pink-300 {
    color: $color-lucid-pink-300;
}

.color-lucid-pink-200 {
    color: $color-lucid-pink-200;
}

.color-lucid-pink-100 {
    color: $color-lucid-pink-100;
}

.color-lucid-pink-50 {
    color: $color-lucid-pink-50;
}

.color-chaste-blossoms-900 {
    color: $color-chaste-blossoms-900;
}

.color-chaste-blossoms-800 {
    color: $color-chaste-blossoms-800;
}

.color-chaste-blossoms-700 {
    color: $color-chaste-blossoms-700;
}

.color-chaste-blossoms-600 {
    color: $color-chaste-blossoms-600;
}

.color-chaste-blossoms-500 {
    color: $color-chaste-blossoms-500;
}

.color-chaste-blossoms-400 {
    color: $color-chaste-blossoms-400;
}

.color-chaste-blossoms-300 {
    color: $color-chaste-blossoms-300;
}

.color-chaste-blossoms-200 {
    color: $color-chaste-blossoms-200;
}

.color-chaste-blossoms-100 {
    color: $color-chaste-blossoms-100;
}

.color-chaste-blossoms-50 {
    color: $color-chaste-blossoms-50;
}

.color-grey-900 {
    color: $color-grey-900;
}

.color-grey-800 {
    color: $color-grey-800;
}

.color-grey-700 {
    color: $color-grey-700;
}

.color-grey-600 {
    color: $color-grey-600;
}

.color-grey-500 {
    color: $color-grey-500;
}

.color-grey-400 {
    color: $color-grey-400;
}

.color-grey-300 {
    color: $color-grey-300;
}

.color-grey-200 {
    color: $color-grey-200;
}

.color-grey-100 {
    color: $color-grey-100;
}

.color-grey-50 {
    color: $color-grey-50;
}

.color-red-900 {
    color: $color-red-900;
}

.color-red-800 {
    color: $color-red-800;
}

.color-red-700 {
    color: $color-red-700;
}

.color-red-600 {
    color: $color-red-600;
}

.color-red-500 {
    color: $color-red-500;
}

.color-red-400 {
    color: $color-red-400;
}

.color-red-300 {
    color: $color-red-300;
}

.color-red-200 {
    color: $color-red-200;
}

.color-red-100 {
    color: $color-red-100;
}

.color-red-50 {
    color: $color-red-50;
}

.color-orange-900 {
    color: $color-orange-900;
}

.color-orange-800 {
    color: $color-orange-800;
}

.color-orange-700 {
    color: $color-orange-700;
}

.color-orange-600 {
    color: $color-orange-600;
}

.color-orange-500 {
    color: $color-orange-500;
}

.color-orange-400 {
    color: $color-orange-400;
}

.color-orange-300 {
    color: $color-orange-300;
}

.color-orange-200 {
    color: $color-orange-200;
}

.color-orange-100 {
    color: $color-orange-100;
}

.color-orange-50 {
    color: $color-orange-50;
}

.color-yellow-900 {
    color: $color-yellow-900;
}

.color-yellow-800 {
    color: $color-yellow-800;
}

.color-yellow-700 {
    color: $color-yellow-700;
}

.color-yellow-600 {
    color: $color-yellow-600;
}

.color-yellow-500 {
    color: $color-yellow-500;
}

.color-yellow-400 {
    color: $color-yellow-400;
}

.color-yellow-300 {
    color: $color-yellow-300;
}

.color-yellow-200 {
    color: $color-yellow-200;
}

.color-yellow-100 {
    color: $color-yellow-100;
}

.color-yellow-50 {
    color: $color-yellow-50;
}

.color-green-900 {
    color: $color-green-900;
}

.color-green-800 {
    color: $color-green-800;
}

.color-green-700 {
    color: $color-green-700;
}

.color-green-600 {
    color: $color-green-600;
}

.color-green-500 {
    color: $color-green-500;
}

.color-green-400 {
    color: $color-green-400;
}

.color-green-300 {
    color: $color-green-300;
}

.color-green-200 {
    color: $color-green-200;
}

.color-green-100 {
    color: $color-green-100;
}

.color-green-50 {
    color: $color-green-50;
}

.color-blue-900 {
    color: $color-blue-900;
}

.color-blue-800 {
    color: $color-blue-800;
}

.color-blue-700 {
    color: $color-blue-700;
}

.color-blue-600 {
    color: $color-blue-600;
}

.color-blue-500 {
    color: $color-blue-500;
}

.color-blue-400 {
    color: $color-blue-400;
}

.color-blue-300 {
    color: $color-blue-300;
}

.color-blue-200 {
    color: $color-blue-200;
}

.color-blue-100 {
    color: $color-blue-100;
}

.color-blue-50 {
    color: $color-blue-50;
}
