@import "./variables";
@import "./typography";

.primary-button {
    padding: 1rem 2rem;
    border-radius: .5rem;
    background-color: $color-migol-blue-500;
    font-family: $archivo;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    color: $color-white;
    border: 0;
    &:hover {
        background-color: $color-migol-blue-400;
    }
    &:active {
        background-color: $color-migol-blue-600;
    }
    &:disabled {
        background-color: $color-grey-300;
    }
}