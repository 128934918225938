/*** Imports ***/

@import "~bootstrap/scss/bootstrap";
@import "./buttons";
@import "./reset";
@import "./variables";
@import "./typography";
@import "./colors";

/* Custom Classes */

.section-spacing {
  margin-bottom: 5rem;
}

.row.equal-cols {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.row.equal-cols > [class*="col-"] > * {
  flex: 1 1 auto;
}

.font-italic {
  font-style: italic;
}

/* Form Inputs Styling */

label,
input,
select {
  font-family: $archivo;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

input {
  &:focus {
    border-color: $color-chaste-blossoms-200;
    box-shadow: 0 0 0 0.25rem rgba(212, 169, 249, 0.25) !important;
  }
}

input[type="checkbox"]:checked {
  background-color: $color-migol-blue-500;
  border-color: $color-migol-blue-500;
}

select {
  padding-block: 1rem !important;
}

/* Main */

// main {
//     background-image: url("../images/landing-page/s-pattern.svg");
//     background-size: cover;
//     background-position: center;
//     background-repeat: no-repeat;
// }

/* Header Component */

.header {
  &__logo {
    background-color: $color-black;
    padding: 0.75rem 0;

    img {
      max-width: 14rem;
    }

    &__image {
    }
  }

  &__phone-numbers {
    background-color: $color-migol-blue-600;
    padding: 0.75rem 0;
    span {
      position: relative;
      bottom: 2px;
    }
    .phone-number--valid {
      border-left: 2px solid $color-white;
      padding-left: 1rem;
    }
  }

  .phone-number__number {
    position: relative;
    bottom: 2px;
  }

  &__phone-number {
    margin-right: 4rem;

    &:last-child {
      margin: 0;
    }

    &__title {
    }

    &__number {
    }
  }
}

/* Masthead Component */

.masthead-section {
  //   background-color: $shimmering-expanse-cyan-50;
  background-color: #f5fdfe;
  padding: 8rem 0 4rem;

  .masthead-section-primary {
  }

  .masthead-pattern-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // background-image: url("../images/landing-page/s-pattern.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
  }

  img {
    // top: 7.5rem;
  }

  .masthead-form-container {
    .form-check-label {
      min-width: 7.375rem;
    }
  }

  .masthead-info-container {
    padding: 2.5rem;
    background-color: $color-amber-glow-200;
    border-radius: 1.5rem;
    position: relative;
    z-index: 1;
  }
}

.who-are-we-section {
  background-color: $color-chaste-blossoms-100;
  padding: 5rem 0;
  background-image: url("../images/landing-page/s-pattern.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    height: 100%;
  }

  .belief-quote-container {
    border-radius: 0 0 0 2rem;
    background: $color-chaste-blossoms-500;
    padding: 2rem 3rem;
  }

  .who-are-we-info-container {
    padding: 4rem;
    background-color: $color-chaste-blossoms-50;
    border-radius: 0 2rem 2rem 0;

    .who-are-we-card {
      background-color: $color-white;
      padding: 0.75rem;
      border-radius: 1rem;
    }
  }
}

/* Our Services Section */

.our-services-section {
  background-color: $color-chaste-blossoms-100;
  padding: 0 0 5rem 0;
  background-image: url("../images/landing-page/s-pattern.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-bottom: 3.5rem;
  }

  .our-service-card {
    background-color: $color-white;
    padding: 2.5rem;
    border-radius: 1.5rem;
  }
}

/* Form Section */

#form-section {
  background-color: $color-amber-glow-100;
  padding: 4rem 0;
  background-image: url("../images/landing-page/s-pattern.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .masthead-info-container {
    padding: 2.5rem;
    background-color: $color-amber-glow-200;
    border-radius: 1.5rem;
    // position: relative;
    z-index: 1;
  }
}

/* Footer */

.footer {
  background-color: $color-grey-900;
  padding: 2rem 0 6rem;
  overflow-x: hidden;

  &__logo {
    img {
      max-width: 14rem;
    }
  }

  hr {
    height: 0.125rem;
    color: $color-white;
    margin-bottom: 3.5rem;
  }

  .social-media-container {
  }

  a.label-label {
    text-decoration: underline;
  }
}

/* Thank You Page */

.thank-you-container {
  background-image: url("../images/thank-you-page/d_masthead-scribbles.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 6rem 0 0;

  .thank-you-pattern-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../images/landing-page/s-pattern.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
  }

  img {
    height: 100%;
  }

  .thank-you-image {
    top: 3px;
  }
}

/* Leads Page */

.leads-page {
  .leads-masthead-container {
    padding: 8rem 0 2rem;
    background-color: $color-chaste-blossoms-100;
  }

  .leads-data-container {
    .leads-data-head {
      padding-bottom: 2rem;
      border-bottom: 1px solid $color-grey-400;
    }

    .leads-data {
      .row {
        padding: 1rem 0;
        border-bottom: 1px solid $color-grey-200;

        &:last-child {
          border: 0;
        }
      }

      .chip {
        border-radius: 1rem;
        background-color: $color-grey-100;
        padding: 0.5rem;
        width: fit-content;
      }

      .chip-secondary {
        border-radius: 1rem;
        border: 1px solid $color-grey-500;
        padding: 0.5rem;
        width: fit-content;
      }
    }
  }

  .leads-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .leads-pagination .page-item .page-link {
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border: 0;
    font-family: $archivo;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .leads-pagination .page-item.active .page-link {
    color: #fff;
    border-radius: 8px;
    background: var(--migol-blue-600, #202c61);
  }

  .leads-pagination .page-item.disabled .page-link {
    color: #ccc;
    background-color: #fff;
    border-color: #ddd;
  }

  .leads-pagination .page-item:first-child .page-link,
  .leads-pagination .page-item:last-child .page-link {
    // border-radius: 50%;
  }
}

/* Authentication Page */

.authentication-page {
  background-color: $color-amber-glow-100;
  min-height: 100vh;
  background-image: url("../images/landing-page/s-pattern.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Terms Page */

.terms-page {
  padding-block: 4rem 2.5rem;
  .terms-container {
    border-radius: 1.5rem;
    background-color: $color-amber-glow-200;
    padding: 2.5rem;
    ol {
      margin-left: 1rem;
    }
  }
}

/* Privacy Policy & Terms & Conditions Pages */

#privacy-policy-page,
#terms-conditions-page {
  padding-block: 4rem 2.5rem;
  background-color: $color-amber-glow-100;
  background-image: url("../images/landing-page/s-pattern.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .terms-container {
    border-radius: 1.5rem;
    background-color: $color-amber-glow-200;
    padding: 2.5rem;
    ul,
    ol {
      margin-left: 1rem;
    }
  }
}

/* Mobile Devices */

@import "./mobile.scss";
