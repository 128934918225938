/* Fonts */

$archivo: 'Archivo', sans-serif;
$degular-text: degular-text, sans-serif;

/* Colors */

$color-black: #000000;
$color-white: #ffffff;

$color-migol-blue-900: #070b18;
$color-migol-blue-800: #101630;
$color-migol-blue-700: #182149;
$color-migol-blue-600: #202c61;
$color-migol-blue-500: #28387a;
$color-migol-blue-400: #505d93;
$color-migol-blue-300: #7983ac;
$color-migol-blue-200: #a2a9c5;
$color-migol-blue-100: #cbcfdf;
$color-migol-blue-50: #f4f5f8;

$color-shimmering-expanse-cyan-900: #0b2e30;
$color-shimmering-expanse-cyan-800: #165d60;
$color-shimmering-expanse-cyan-700: #218c91;
$color-shimmering-expanse-cyan-600: #2cbbc1;
$color-shimmering-expanse-cyan-500: #38eaf2;
$color-shimmering-expanse-cyan-400: #5dedf4;
$color-shimmering-expanse-cyan-300: #83f1f6;
$color-shimmering-expanse-cyan-200: #a9f5f9;
$color-shimmering-expanse-cyan-100: #cff9fb;
$color-shimmering-expanse-cyan-50: #f5fdfe;

$color-amber-glow-900: #301f0b;
$color-amber-glow-800: #603e16;
$color-amber-glow-700: #915e21;
$color-amber-glow-600: #c17d2c;
$color-amber-glow-500: #f29d38;
$color-amber-glow-400: #f4af5d;
$color-amber-glow-300: #f6c283;
$color-amber-glow-200: #f9d4a9;
$color-amber-glow-100: #fbe7cf;
$color-amber-glow-50: #fefaf5;

$color-lucid-pink-900: #320930;
$color-lucid-pink-800: #661261;
$color-lucid-pink-700: #991c92;
$color-lucid-pink-600: #cc25c3;
$color-lucid-pink-500: #ff2ff4;
$color-lucid-pink-400: #ff56f6;
$color-lucid-pink-300: #ff7ef8;
$color-lucid-pink-200: #ffa5fa;
$color-lucid-pink-100: #ffcdfc;
$color-lucid-pink-50: #fff4fe;

$color-chaste-blossoms-900: #1f0b30;
$color-chaste-blossoms-800: #3e1660;
$color-chaste-blossoms-700: #5e2191;
$color-chaste-blossoms-600: #7d2cc1;
$color-chaste-blossoms-500: #9d38f2;
$color-chaste-blossoms-400: #af5df4;
$color-chaste-blossoms-300: #c283f6;
$color-chaste-blossoms-200: #d4a9f9;
$color-chaste-blossoms-100: #e7cffb;
$color-chaste-blossoms-50: #faf5fe;

$color-grey-900: #101010;
$color-grey-800: #202020;
$color-grey-700: #303030;
$color-grey-600: #404040;
$color-grey-500: #515151;
$color-grey-400: #727272;
$color-grey-300: #939393;
$color-grey-200: #b4b4b4;
$color-grey-100: #d5d5d5;
$color-grey-50: #f6f6f6;

$color-red-900: #260d12;
$color-red-800: #4d1c25;
$color-red-700: #742a38;
$color-red-600: #9b384b;
$color-red-500: #c2465e;
$color-red-400: #cd697c;
$color-red-300: #d98c9b;
$color-red-200: #e4afb9;
$color-red-100: #f0d2d8;
$color-red-50: #fbf5f6;

$color-orange-900: #2d1c07;
$color-orange-800: #5a380e;
$color-orange-700: #885515;
$color-orange-600: #b5711c;
$color-orange-500: #e38e24;
$color-orange-400: #e8a34d;
$color-orange-300: #edb877;
$color-orange-200: #f2cea0;
$color-orange-100: #f8e3ca;
$color-orange-50: #fdf9f4;

$color-yellow-900: #322a0a;
$color-yellow-800: #665414;
$color-yellow-700: #997f1e;
$color-yellow-600: #cca928;
$color-yellow-500: #ffd433;
$color-yellow-400: #ffdc59;
$color-yellow-300: #ffe480;
$color-yellow-200: #ffeca7;
$color-yellow-100: #fff4ce;
$color-yellow-50: #fffcf4;

$color-green-900: #12260d;
$color-green-800: #254d1c;
$color-green-700: #38742a;
$color-green-600: #4b9b38;
$color-green-500: #5ec246;
$color-green-400: #7ccd69;
$color-green-300: #9bd98c;
$color-green-200: #b9e4af;
$color-green-100: #d8f0d2;
$color-green-50: #f6fbf5;

$color-blue-900: #09192f;
$color-blue-800: #12335e;
$color-blue-700: #1c4c8e;
$color-blue-600: #2566bd;
$color-blue-500: #2f80ed;
$color-blue-400: #5698f0;
$color-blue-300: #7eb0f3;
$color-blue-200: #a5c8f7;
$color-blue-100: #cde0fa;
$color-blue-50: #f4f8fe;



