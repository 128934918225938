@import "./variables";

h1 {
    font-size: 3rem;
    font-family: $degular-text;
    font-weight: 700;
    line-height: 3.5rem;
}

h2 {
    font-size: 2.5rem;
    font-family: $degular-text;
    font-weight: 700;
    line-height: 3rem;
}

h3 {
    font-size: 2rem;
    font-family: $degular-text;
    font-weight: 700;
    line-height: 2.5rem;
}

h4 {
    font-size: 1.5rem;
    font-family: $degular-text;
    font-weight: 700;
    line-height: 2rem;
}

.b1 {
    font-size: 1.25rem;
    font-family: $archivo;
    font-weight: 400;
    line-height: 1.75rem;
}

.b2 {
    font-size: 18px;
    font-family: $archivo;
    font-weight: 400;
    line-height: 26px;
}

.b3 {
    font-size: 1rem;
    font-family: $archivo;
    font-weight: 400;
    line-height: 1.5rem;
}

.label-button-lg {
    font-size: 1.25rem;
    font-family: $archivo;
    font-weight: 500;
    line-height: 1.75rem;
}

.label-button-md {
    font-size: 1rem;
    font-family: $archivo;
    font-weight: 500;
    line-height: 1.5rem;
}

.label-overline-lg {
    font-size: 0.875rem;
    font-family: $degular-text;
    font-weight: 300;
    line-height: 1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.label-overline-md {
    font-size: .75rem;
    font-family: $degular-text;
    font-weight: 300;
    line-height: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;
}

.label-caption {
    font-size: 10px;
    font-family: $archivo;
    font-weight: 500;
    line-height: .75rem;
}

.label-label {
    font-size: 1rem;
    font-family: $archivo;
    font-weight: 500;
    line-height: 1.5rem;
}

.label-quote-lg {
    font-size: 1.25rem;
    font-family: $archivo;
    font-weight: 500;
    font-style: italic;
    line-height: 1.75rem;
}

.label-quote-md {
    font-size: 1rem;
    font-family: $archivo;
    font-weight: 400;
    font-style: italic;
    line-height: 1.5rem;
}

.label-placeholder {
    font-family: $archivo;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
}

/* Media Queries for Mobile Devices*/

@media screen and (max-width: 768px) {

    h1 {
        font-size: 2.5rem;
        font-family: $degular-text;
        font-weight: 700;
        line-height: 3rem;
    }

    h2 {
        font-size: 2rem;
        font-family: $degular-text;
        font-weight: 700;
        line-height: 2.5rem;
    }

    h3 {
        font-size: 1.5rem;
        font-family: $degular-text;
        font-weight: 700;
        line-height: 2rem;
    }

    h4 {
        font-size: 1.25rem;
        font-family: $degular-text;
        font-weight: 700;
        line-height: 1.75rem;
    }

    .mobile-b-1 {
        font-size: 1rem;
        font-family: $archivo;
        font-weight: 400;
        line-height: 1.5rem;
    }

    .mobile-b-2 {
        font-size: 0.875rem;
        font-family: $archivo;
        font-weight: 400;
        line-height: 1.375rem;
    }

    .mobile-b-3 {
        font-size: .75rem;
        font-family: $archivo;
        font-weight: 400;
        line-height: 1.25rem;
    }
}